<template>
  <v-row justify="center">
    <v-dialog v-model="_dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Show Price ? </v-card-title>
        <v-card-text>Do you want to show the Price & Total in the excel sheet ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="no()"> No </v-btn>
          <v-btn color="blue darken-1" text @click="yes()"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "PriceOptionModal",
  props: ["dialog"],
  computed: {
    _dialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        this.$emit("update:dialog", value);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    yes() {
      this._dialog = false;
      this.$emit("download", true);
    },
    no() {
      this._dialog = false;
      this.$emit("download", false);
    },
  },
};
</script>
