import axios from "axios";
import { forceFileDownload } from "../../../common/utils";

async function getConfirmedOrders({ startDate, endDate }) {
  let queryStr = "";
  if (startDate) {
    queryStr = `?startDate=${startDate}&endDate=${endDate}`;
  }

  try {
    const res = await axios.get(`order-admin/confirmedOrders${queryStr}`);
    return res.data;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

async function readyToShipItems(data) {
  try {
    const response = await axios.patch(`order-admin/readyToShipItems`, data);
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

async function rejectProducts(data) {
  try {
    const response = await axios.patch(`order-admin/rejectItems/?rejectionStage=CONFIRMED`, data);
    return response.data;
  } catch (error) {
    console.log(JSON.stringify(error));
    throw error;
  }
}

async function downloadSupplierOrderRequestExcel(ids, showCost, action) {
  const body = {
    ids,
    showCost,
    action,
  };
  try {
    const response = await axios.post(`excel-generation/GenerateSupplierWiseOrderRequest`, body, { responseType: "arraybuffer" });
    forceFileDownload(response, "Supplier Wise Branch Orders");
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

export { getConfirmedOrders, readyToShipItems, downloadSupplierOrderRequestExcel, rejectProducts };
